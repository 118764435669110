<template>
  <div>
    <select :class="class" :id="id" @change="(ev) => $emit('update:modelValue', ev.target.value)" placeholder="State">
      <option value="" :selected="modelValue == ''" disabled>State/Territory</option>
      <optgroup label="States">
        <option :selected="modelValue == 'AL'" value="AL">Alabama</option>
        <option :selected="modelValue == 'AK'" value="AK">Alaska</option>
        <option :selected="modelValue == 'AZ'" value="AZ">Arizona</option>
        <option :selected="modelValue == 'AR'" value="AR">Arkansas</option>
        <option :selected="modelValue == 'CA'" value="CA">California</option>
        <option :selected="modelValue == 'CO'" value="CO">Colorado</option>
        <option :selected="modelValue == 'CT'" value="CT">Connecticut</option>
        <option :selected="modelValue == 'DE'" value="DE">Delaware</option>
        <option :selected="modelValue == 'DC'" value="DC">District Of Columbia</option>
        <option :selected="modelValue == 'FL'" value="FL">Florida</option>
        <option :selected="modelValue == 'GA'" value="GA">Georgia</option>
        <option :selected="modelValue == 'HI'" value="HI">Hawaii</option>
        <option :selected="modelValue == 'ID'" value="ID">Idaho</option>
        <option :selected="modelValue == 'IL'" value="IL">Illinois</option>
        <option :selected="modelValue == 'IN'" value="IN">Indiana</option>
        <option :selected="modelValue == 'IA'" value="IA">Iowa</option>
        <option :selected="modelValue == 'KS'" value="KS">Kansas</option>
        <option :selected="modelValue == 'KY'" value="KY">Kentucky</option>
        <option :selected="modelValue == 'LA'" value="LA">Louisiana</option>
        <option :selected="modelValue == 'ME'" value="ME">Maine</option>
        <option :selected="modelValue == 'MD'" value="MD">Maryland</option>
        <option :selected="modelValue == 'MA'" value="MA">Massachusetts</option>
        <option :selected="modelValue == 'MI'" value="MI">Michigan</option>
        <option :selected="modelValue == 'MN'" value="MN">Minnesota</option>
        <option :selected="modelValue == 'MS'" value="MS">Mississippi</option>
        <option :selected="modelValue == 'MO'" value="MO">Missouri</option>
        <option :selected="modelValue == 'MT'" value="MT">Montana</option>
        <option :selected="modelValue == 'NE'" value="NE">Nebraska</option>
        <option :selected="modelValue == 'NV'" value="NV">Nevada</option>
        <option :selected="modelValue == 'NH'" value="NH">New Hampshire</option>
        <option :selected="modelValue == 'NJ'" value="NJ">New Jersey</option>
        <option :selected="modelValue == 'NM'" value="NM">New Mexico</option>
        <option :selected="modelValue == 'NY'" value="NY">New York</option>
        <option :selected="modelValue == 'NC'" value="NC">North Carolina</option>
        <option :selected="modelValue == 'ND'" value="ND">North Dakota</option>
        <option :selected="modelValue == 'OH'" value="OH">Ohio</option>
        <option :selected="modelValue == 'OK'" value="OK">Oklahoma</option>
        <option :selected="modelValue == 'OR'" value="OR">Oregon</option>
        <option :selected="modelValue == 'PA'" value="PA">Pennsylvania</option>
        <option :selected="modelValue == 'RI'" value="RI">Rhode Island</option>
        <option :selected="modelValue == 'SC'" value="SC">South Carolina</option>
        <option :selected="modelValue == 'SD'" value="SD">South Dakota</option>
        <option :selected="modelValue == 'TN'" value="TN">Tennessee</option>
        <option :selected="modelValue == 'TX'" value="TX">Texas</option>
        <option :selected="modelValue == 'UT'" value="UT">Utah</option>
        <option :selected="modelValue == 'VT'" value="VT">Vermont</option>
        <option :selected="modelValue == 'VA'" value="VA">Virginia</option>
        <option :selected="modelValue == 'WA'" value="WA">Washington</option>
        <option :selected="modelValue == 'WV'" value="WV">West Virginia</option>
        <option :selected="modelValue == 'WI'" value="WI">Wisconsin</option>
        <option :selected="modelValue == 'WY'" value="WY">Wyoming</option>
      </optgroup>
      <optgroup label="US Territories">
        <option :selected="modelValue == 'AS'" value="AS">American Samoa</option>
        <option :selected="modelValue == 'GU'" value="GU">Guam</option>
        <option :selected="modelValue == 'MP'" value="MP">Northern Mariana Islands</option>
        <option :selected="modelValue == 'PR'" value="PR">Puerto Rico</option>
        <option :selected="modelValue == 'UM'" value="UM">United States Minor Outlying Islands</option>
        <option :selected="modelValue == 'VI'" value="VI">Virgin Islands</option>
      </optgroup>
    </select>
  </div>
</template>

<script>
export default {
  props: ['modelValue', 'class', 'id'],
  emits: ['update:modelValue']
}
</script>
