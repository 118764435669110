<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div class="text-center">
        <div class="bg-white p-5 rounded-lg" :class="scoreBoxCss(tucScore)">
          <div><span class="text-[40px] leading-[40px] text-base-60">{{ tucScore }}</span> <span class="text-[19px] leading-[19px] text-base-40">/ 850</span></div>
          <div class="font-medium mt-3 uppercase inline-block rounded-md px-4 py-1" :class="scoreLabelCss(tucScore)">{{ scoreAdjective(tucScore) }}</div>
        </div>
        <div class="mt-5 opacity-60 pt-1">
          <img src="/images/bureau_logos/transunion_bw.svg" class="w-32 mx-auto" alt="TransUnion" />
        </div>
      </div>
      <div class="text-center" v-if="eqfScore">
        <div class="bg-white p-5 rounded-lg" :class="scoreBoxCss(eqfScore)">
          <div><span class="text-[40px] leading-[40px] text-base-60">{{ eqfScore }}</span> <span class="text-[19px] leading-[19px] text-base-40">/ 850</span></div>
          <div class="font-medium mt-3 uppercase inline-block rounded-md px-4 py-1" :class="scoreLabelCss(eqfScore)">{{ scoreAdjective(eqfScore) }}</div>
        </div>
        <div class="mt-5 opacity-60 pt-2">
          <img src="/images/bureau_logos/equifax_bw.svg" class="w-24 mx-auto" alt="Equifax" />
        </div>
      </div>
      <div class="text-center" v-if="expScore">
        <div class="bg-white p-5 rounded-lg" :class="scoreBoxCss(expScore)">
          <div><span class="text-[40px] leading-[40px] text-base-60">{{ expScore }}</span> <span class="text-[19px] leading-[19px] text-base-40">/ 850</span></div>
          <div class="font-medium mt-3 uppercase inline-block rounded-md px-4 py-1" :class="scoreLabelCss(expScore)">{{ scoreAdjective(expScore) }}</div>
        </div>
        <div class="mt-5 opacity-60">
          <img src="/images/bureau_logos/experian_bw.svg" class="w-24 mx-auto" alt="Experian" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "tucScore",
    "expScore",
    "eqfScore"
  ],
  methods: {
    scoreAdjective(score) {
      if (score < 629) {
        return "POOR";
      } else if (score < 719) {
        return "FAIR";
      } else {
        return "GOOD";
      }
    },
    scoreBoxCss(score) {
      if (score < 629) {
        return "bg-status-negative-10 border border-status-negative-20";
      } else if (score < 719) {
        return "bg-status-neutral-10 border border-status-neutral-20";
      } else {
        return "bg-status-positive-10 border border-status-positive-20";
      }
    },
    scoreLabelCss(score) {
      if (score < 629) {
        return "bg-status-negative-20";
      } else if (score < 719) {
        return "bg-status-neutral-20";
      } else {
        return "bg-status-positive-20";
      }
    }
  }
}
</script>
