<template>
  <div>
    <input
      id="ssn-input"
      type="password"
      :class="class"
      v-model="ssnRaw"
      @input="(ev) => $emit('ssn-input', ssnRaw)"
      maxlength="9"
      placeholder="Social Security Number"
      data-lpignore="true"
      autocomplete="off" />
  </div>
</template>

<script>
export default {
  props: ["ssn", "class"],
  emits: ["ssn-input"],
  data: function () {
    return {
      ssnRaw: "",
    };
  },
  mounted() {
    this.ssnRaw = this.ssn;
    window.document.getElementById("ssn-input").focus();
  },
};
</script>
