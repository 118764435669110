<template>
  <div id="step-identity-verification">
    <PrivacySvg class="my-8" />
    <form action="/" v-on:submit.prevent="submit()" v-on:keyup.enter="submit()">
      <div class="w-full mx-auto">
        <h2 class="text-3xl text-base-60 font-medium">Verification Information</h2>
        <p class="text-base-50 font-light text-[20px] my-2">Protecting your credit and personal information is our top priority.</p>

        <div class="border p-4 mt-5">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <label for="ssn">Social Security Number</label>
              <SSNInput :ssn="ssn" @ssn-input="(inputSsn) => (ssn = inputSsn)" />
              <div class="validation-error" v-if="validationErrors.ssn">{{ validationErrors.ssn }}</div>
            </div>
            <div>
              <label for="date_of_birth">Date of Birth</label>
              <div class="grid grid-cols-3 gap-1">
                <select class="" v-model="dobMonth" id="dob_month">
                  <option value="" disabled>Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <input
                  id="dob_day"
                  type="text"
                  class=""
                  inputmode="numeric"
                  v-model="dobDay"
                  placeholder="Day" />
                <input
                  id="dob_year"
                  type="text"
                  inputmode="numeric"
                  class=""
                  v-model="dobYear"
                  placeholder="Year" />
              </div>
              <div class="validation-error" v-if="validationErrors.dateOfBirth">{{ validationErrors.dateOfBirth }}</div>
            </div>
          </div>

          <p class="text-xs text-slate-400 my-3">
            By clicking "Submit & Continue" below, you agree to and accept the Terms and Conditions and Privacy Policy, and confirm your authorization for Standard Scores LLC to obtain your credit profile from any consumer reporting agency
            for your own use, to confirm your identity, to notify you of credit opportunities and other products that may be available, and to monitor your credit for changes.
          </p>
        </div>

        <div v-if="validationErrors.general" class="validation-error my-3">
          <div v-for="error in validationErrors.general" v-bind:key="error">
            {{ error }}
          </div>
        </div>

        <div class="my-5">
          <button id="btn-submit" type="button" class="w-full px-3 py-4 text-[34px] leading-[46px] text-base-60 font-medium bg-accent-30 hover:bg-amber-500" v-on:click="submit()" :disabled="showSpinner">
            <span v-if="!showSpinner">Submit & Continue</span>
            <Spinner v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "../shared/spinner_svg.vue";
import SSNInput from "../ccs_auth/ssn_input.vue";
import PrivacySvg from "./components/privacy_svg.vue";

export default {
  name: "RegistrationFormV2Step2",
  components: {
    SSNInput,
    Spinner,
    PrivacySvg
  },
  props: ["userProfile"],
  data: () => ({
    ssn: "",
    dobMonth: "",
    dobDay: "",
    dobYear: "",
    trustBlackbox: "",
    showSpinner: false,
    validationErrors: {}
  }),
  methods: {
    submit() {
      this.validationErrors = {};
      this.validate();

      if (Object.keys(this.validationErrors).length > 0) {
        return;
      }

      this.showSpinner = true;
      this.setTrustBlackbox();
      axios.post("/sign-up/verify-identity", {
        ssn: this.ssn,
        dob_month: this.dobMonth,
        dob_day: this.dobDay,
        dob_year: this.dobYear,
        trust_blackbox: this.trustBlackbox
      }).then((response) => {
        if (response.data.result === "success") {
          this.$emit("next-step");
        } else {
          this.validationErrors.general = response.data.errors
        }
      }).catch((error) => {
        console.error(error);
        this.validationErrors.general = ["An error occurred while attempting to process your request"];
      }).finally(() => {
        this.showSpinner = false;
      });
    },
    validate() {
      if (!this.ssn) {
        this.validationErrors.ssn = "Social Security Number is required";
      }

      if (this.ssn.length < 9) {
        this.validationErrors.ssn = "Social Security Number must be 9 digits";
      }

      if (!this.dobMonth || !this.dobDay || !this.dobYear) {
        this.validationErrors.dateOfBirth = "Date of birth is required";
      }
    },
    setTrustBlackbox: function () {
      try {
        this.trustBlackbox = window.IGLOO.getBlackbox().blackbox;
      } catch (e) {
        this.trustBlackbox = "";
      }
    },
  },
  mounted() {
    this.user = this.userProfile;

    if (this.user.dateOfBirth) {
      this.dobYear = this.user.dateOfBirth.split("-")[0];
      this.dobMonth = this.user.dateOfBirth.split("-")[1];
      this.dobDay = this.user.dateOfBirth.split("-")[2];
    }

    if (window.location.href.indexOf("localhost:4000") > -1) {
      this.ssn = "899010006";
      this.dobMonth = "9";
      this.dobDay = "1";
      this.dobYear = "1957";
    }
  }
}
</script>
