<template>
  <div id="step-registration">
    <PrivacySvg class="my-8" />
    <form action="/" v-on:submit.prevent="nextStep" v-on:keyup.enter="nextStep">
      <div class="w-full mx-auto">
        <h2 class="text-[24px] leading-[32px] lg:text-[32px] lg:leading-[40px] text-base-60 font-medium my-8">Please provide your address<br />to locate your credit file.</h2>

        <div class="border rounded-sm p-4 my-8">
          <div class="text-[16px] text-base-60 mb-8">
            What is the primary reason for checking your credit score? (Please Select One)
          </div>

          <div class="grid grid-cols-2 md:grid-cols-4 gap-3 mt-4">
            <div class="border rounded-md px-3 py-4 cursor-pointer text-[17px] leading-[24px]" v-for="reason in creditCheckReasonOptions" v-on:click="user.creditCheckReason = reason.id"
              v-bind:class="{ 'bg-accent-10 border-accent-20 text-base-60': user.creditCheckReason === reason.id, 'text-base-50': user.creditCheckReason !== reason.id }"
              v-bind:key="reason.id">
              {{ reason.label }}
            </div>
          </div>
        </div>

        <div class="border rounded-sm p-4 my-8">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-5">
            <div>
              <label for="first_name">First Name</label>
              <input type="text" id="first_name" v-model="user.firstName" placeholder="Enter first name" />
              <div v-if="validationErrors.firstName" class="validation-error">{{ validationErrors.firstName }}</div>
            </div>
            <div>
              <label for="last_name">Last Name</label>
              <input type="text" id="last_name" v-model="user.lastName" placeholder="Enter last name" />
              <div v-if="validationErrors.lastName" class="validation-error">{{ validationErrors.lastName }}</div>
            </div>

            <div>
              <label for="address_line_1">Address Line 1</label>
              <input type="text" id="address_line_1" v-model="user.address.addressLine1" placeholder="Enter address line 1" />
              <div v-if="validationErrors.addressLine1" class="validation-error">{{ validationErrors.addressLine1 }}</div>
            </div>
            <div>
              <label for="address_line_2">Address Line 2 (optional)</label>
              <input type="text" id="address_line_2" v-model="user.address.addressLine2" placeholder="Enter address line 2" />
            </div>
            <div>
              <label for="city">City</label>
              <input type="text" id="city" v-model="user.address.city" placeholder="Enter city" />
              <div v-if="validationErrors.city" class="validation-error">{{ validationErrors.city }}</div>
            </div>
            <div>
              <label for="state">State</label>
              <StateSelect id="state" v-model="user.address.state" class="text-base-50" />
              <div v-if="validationErrors.state" class="validation-error">{{ validationErrors.state }}</div>
            </div>
            <div>
              <label for="zip">Zip</label>
              <input type="text" id="zip" v-model="user.address.zip" placeholder="Enter zip" />
              <div v-if="validationErrors.zip" class="validation-error">{{ validationErrors.zip }}</div>
            </div>
            <div>
              <label for="phone">Phone</label>
              <PhoneNumberInput id="phone" v-model:phoneNumber="user.phone" />
              <div v-if="validationErrors.phone" class="validation-error">{{ validationErrors.phone }}</div>
              <div class="text-[12px] text-base-40 font-light mt-1">
                * We will use this phone number to verify your identity.
              </div>
            </div>

            <div class="md:col-span-2">
              <label class="block checkbox-container">
                <input type="checkbox" v-model="user.useCurrentAddress" />
                <div class="checkmark"></div>
                I have resided at this address for at least 6 months
              </label>
            </div>

            <div v-if="!user.useCurrentAddress" class="md:col-span-2 text-base-50">
              Please enter your previous street address:
            </div>

            <div v-if="!user.useCurrentAddress">
              <label for="previous_address_line_1">Address Line 1</label>
              <input type="text" id="previous_address_line_1" v-model="user.previousAddress.addressLine1" placeholder="Enter previous address line 1" />
            </div>

            <div v-if="!user.useCurrentAddress">
              <label for="previous_address_line_2">Address Line 2</label>
              <input type="text" id="previous_address_line_2" v-model="user.previousAddress.addressLine2" placeholder="Enter previous address line 2" />
            </div>

            <div v-if="!user.useCurrentAddress">
              <label for="previous_city">City</label>
              <input type="text" id="previous_city" v-model="user.previousAddress.city" placeholder="Enter previous city" />
            </div>

            <div v-if="!user.useCurrentAddress">
              <label for="previous_state">State</label>
              <StateSelect id="previous_state" v-model="user.previousAddress.state" class="text-base-50" />
            </div>

            <div v-if="!user.useCurrentAddress">
              <label for="previous_zip">Zip</label>
              <input type="text" id="previous_zip" v-model="user.previousAddress.zip" placeholder="Enter previous zip" />
            </div>

            <div class="col-span-full">
              <div class="border-b my-3"></div>
            </div>

            <div>
              <label for="email">Email</label>
              <input type="text" id="email" v-model="user.email" placeholder="Enter email" />
              <div v-if="validationErrors.email" class="validation-error">{{ validationErrors.email }}</div>
            </div>
            <div>
              <label for="password">Password</label>
              <div class="relative">
                <input :type="showPassword ? 'text' : 'password'" id="password" v-model="user.password" placeholder="Enter password" />
                <span class="absolute right-3 top-3 cursor-pointer" v-on:click="togglePasswordVisibility">
                  <EyeIcon class="w-4 h-4 text-base-50" v-if="showPassword" />
                  <EyeSlashIcon class="w-4 h-4 text-base-50" v-else />
                </span>
              </div>
              <div v-if="validationErrors.password" class="validation-error">{{ validationErrors.password }}</div>
              <div class="text-[12px] text-base-40 font-light mt-1">
                * Must contain at least 8 characters including 1 number, 1 uppercase letter, 1 lowercase letter, and 1 special character.
              </div>
            </div>
            <div class="md:col-span-2 mt-6">
              <label for="marketing_consent" class="checkbox-container">
                <input type="checkbox" id="marketing_consent" v-model="user.marketingConsent" />
                <div class="checkmark"></div>
                Yes, please send special offers from Standard Scores to my email.
              </label>
            </div>
          </div>
        </div>

        <div v-if="validationErrors.general" class="validation-error my-3">
          <div v-for="error in validationErrors.general" v-bind:key="error">
            {{ error }}
          </div>
        </div>

        <div class="my-5">
          <button id="btn-submit" type="button" class="w-full px-3 py-4 text-[34px] leading-[46px] text-base-60 font-medium bg-accent-30 hover:bg-amber-500" v-on:click="submit()" :disabled="showSpinner">
            <span v-if="!showSpinner">Submit & Continue</span>
            <Spinner v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios"
import Spinner from "../shared/spinner_svg.vue";
import StateSelect from "../registration/state_select.vue"
import PhoneNumberInput from "./components/phone_number_input.vue"
import PrivacySvg from "./components/privacy_svg.vue"
import { EyeIcon, EyeSlashIcon } from "@heroicons/vue/24/solid"

export default {
  name: "RegistrationFormV2Step1",
  props: {
    userProfile: {
      type: Object,
      required: true
    }
  },
  components: {
    StateSelect,
    Spinner,
    PrivacySvg,
    EyeIcon,
    EyeSlashIcon,
    PhoneNumberInput
  },
  data: () => ({
    showSpinner: false,
    showPassword: false,
    user: {
      creditCheckReason: "recently_denied_credit",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      useCurrentAddress: true,
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      },
      previousAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      },
      marketingConsent: true
    },
    validationErrors: {}
  }),
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    validateFields() {
      this.validationErrors = {};

      if (!this.user.firstName) {
        this.validationErrors.firstName = "First name is required";
      }

      if (!this.user.lastName) {
        this.validationErrors.lastName = "Last name is required";
      }

      if (!this.user.email) {
        this.validationErrors.email = "Email is required";
      }

      if (!this.user.phone) {
        this.validationErrors.phone = "Phone number is required";
      }

      if (!this.user.password) {
        this.validationErrors.password = "Password is required";
      }

      if (!this.user.address.addressLine1) {
        this.validationErrors.addressLine1 = "Address line 1 is required";
      }

      if (!this.user.address.city) {
        this.validationErrors.city = "City is required";
      }

      if (!this.user.address.state) {
        this.validationErrors.state = "State is required";
      }

      if (!this.user.address.zip) {
        this.validationErrors.zip = "Zip is required";
      }

      if (!this.user.useCurrentAddress && !this.user.previousAddress.addressLine1) {
        this.validationErrors.previousAddressLine1 = "Previous address line 1 is required";
      }

      if (!this.user.useCurrentAddress && !this.user.previousAddress.city) {
        this.validationErrors.previousCity = "Previous city is required";
      }

      if (!this.user.useCurrentAddress && !this.user.previousAddress.state) {
        this.validationErrors.previousState = "Previous state is required";
      }

      if (!this.user.useCurrentAddress && !this.user.previousAddress.zip) {
        this.validationErrors.previousZip = "Previous zip is required";
      }
    },
    attemptUserRegistration() {
      axios.post("/sign-up/register-user", {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        phone: this.user.phone.replace(/\D/g, ''),
        email: this.user.email,
        password: this.user.password,
        address: {
          address_line_1: this.user.address.addressLine1,
          address_line_2: this.user.address.addressLine2,
          city: this.user.address.city,
          state: this.user.address.state,
          zip: this.user.address.zip
        },
        previous_address: {
          address_line_1: this.user.previousAddress.addressLine1,
          address_line_2: this.user.previousAddress.addressLine2,
          city: this.user.previousAddress.city,
          state: this.user.previousAddress.state,
          zip: this.user.previousAddress.zip
        },
        use_previous_address: !this.user.useCurrentAddress
      })
        .then(response => {
          if (response.data.result === "success") {
            this.$emit("next-step", this.user)
          } else {
            this.validationErrors.general = response.data.errors
          }
        })
        .catch(error => {
          console.error(error)
          this.validationErrors.general = ["An error occurred while submitting the form. Please try again."]
        })
        .finally(() => {
          this.showSpinner = false;
        })
    },
    submit() {
      this.showSpinner = true;
      this.validateFields();

      if (Object.keys(this.validationErrors).length > 0) {
        this.showSpinner = false;
        return;
      }

      this.attemptUserRegistration()
    }
  },
  computed: {
    creditCheckReasonOptions() {
      return [
        { id: "recently_denied_credit", label: "Recently Denied Credit" },
        { id: "improve_credit_score", label: "Improve Credit Score" },
        { id: "buying_a_car", label: "Buying a Car" },
        { id: "victim_of_identity_theft", label: "Victim of ID Theft" },
        { id: "buying_a_home", label: "Buying a Home" },
        { id: "get_out_of_debt", label: "Get Out of Debt" },
        { id: "fix_my_credit", label: "Fix My Credit" },
        { id: "just_curious", label: "Just Curious" },
      ];
    }
  },
  mounted() {
    this.user = this.userProfile;

    if (window.location.href.indexOf("localhost:4000") > -1) {
      this.user.email = `rohitn+${new Date().getTime()}@creditsage.com`;
      this.user.phone = `4045049006`;
      this.user.firstName = "Frank";
      this.user.lastName = "Testf";
      this.user.address.addressLine1 = "1071 101ST";
      this.user.address.addressLine2 = "";
      this.user.address.city = "Hollywood";
      this.user.address.state = "FL";
      this.user.address.zip = "33025";
      this.user.dobMonth = "9";
      this.user.dobDay = "01";
      this.user.dobYear = "1957";
      this.user.password = "StrongPass123!";
    }
  }
}
</script>
