<script>
import axios from "axios";

import { store } from "./store.ts";

import creditScoreFactors from "./credit_score_factors.ts";
import CreditReportParser from "../../lib/credit_report_parser.ts";
import TrendingDataParser from "../../lib/trending_data_parser.ts";

import Dashboard from "./dashboard/dashboard_container.vue";
import AlertsIndex from "./alerts_index.vue";
import UserProfile from "./user_profile.vue";
import UserBilling from "./user_billing.vue"
import ContactUs from "./contact_us.vue"
import CreditReport from "./credit_report/display.vue";
import CreditReportPrintView from "./credit_report/print.vue";
import AvailableCredit from "./education/available_credit.vue";
import PaymentHistory from "./education/payment_history.vue";
import CreditUtilization from "./education/credit_utilization.vue";
import AgeOfCredit from "./education/age_of_credit.vue";
import NegativeMarks from "./education/negative_marks.vue";
import CreditInquiries from "./education/credit_inquiries.vue";
import TopNav from "./top_nav.vue";
import FooterNav from "./footer_nav.vue";

const routes = {
  "/": Dashboard,
  "/dashboard": Dashboard,
  "/alerts": AlertsIndex,
  "/profile": UserProfile,
  "/billing": UserBilling,
  "/contact-us": ContactUs,
  "/credit-report": CreditReport,
  "/credit-report/print": CreditReportPrintView,
  "/education/available-credit": AvailableCredit,
  "/education/credit-utilization": CreditUtilization,
  "/education/age-of-credit": AgeOfCredit,
  "/education/negative-marks": NegativeMarks,
  "/education/credit-inquiries": CreditInquiries,
  "/education/payment-history": PaymentHistory,
};

export default {
  components: {
    TopNav: TopNav,
    FooterNav: FooterNav,
  },
  data() {
    return {
      currentPath: "",
      alertText: "",
      noticeText: "",
      store
    };
  },
  computed: {
    currentView() {
      return routes[this.currentPath || "/"] || Dashboard;
    },
  },
  methods: {
    loadCreditReportData(data) {
      try {
        if (Object.keys(data).length == 0 || data.ErrorCode == "8") {
          if (store.reportLoadAttempts == 0) {
            store.reportLoadAttempts = 1;
            this.loadCreditReportScript(true);
          } else {
            store.creditReportError = true;
          }
        }else{
          store.creditReport = CreditReportParser.parse(data.BundleComponents.BundleComponent);
          store.scoreFactors = creditScoreFactors(store.creditReport);
        }
      } catch (e) {
        window.Sentry.captureException(e);
      }
    },
    loadScoreHistoryData(data) {
      store.scoreFetched = true
      store.creditScoreHistory = TrendingDataParser.parse(data.BundleComponents.BundleComponent);
    },
    logLoadError(e) {
      window.Sentry.captureException(e);
    },
    loadCreditReportScript(retry = false) {
      const script = document.createElement("script");
      script.async = true;
      script.type = "application/javascript";
      script.src = `/portal/credit-report.js${retry ? "?force=true" : ""}`;
      script.addEventListener("load", () => {
        this.loadCreditReportData(window.data);
      });
      script.addEventListener("error", () => {
        this.logLoadError();
        store.creditReportError = true;
      });

      document.body.appendChild(script);
    },
    loadScoreHistoryScript() {
      const script = document.createElement("script");
      script.async = true;
      script.type = "application/javascript";
      script.src = "/portal/score-history.js";
      script.addEventListener("load", () => {
        this.loadScoreHistoryData(window.data);
      });
      script.addEventListener("error", this.logLoadError);

      document.body.appendChild(script);
    },
    mountScriptTags() {
      this.loadCreditReportScript();
      this.loadScoreHistoryScript();
    },
    navigateToPath(newPath) {
      if (!newPath) {
        window.location.hash = "#/";
        return;
      } else if (!routes[newPath]) {
        window.location.hash = "#/";
        window.dispatchEvent(
          new CustomEvent("ss-global-alert", {
            detail: "That page is not available",
          })
        );
      }

      this.currentPath = newPath;
    },
    fetchProfile() {
      axios.get("/portal/profile").then((response) => {
        store.profile.email = response.data.email;
        store.profile.alt_email = response.data.alt_email;
        store.profile.firstName = response.data.first_name;
        store.profile.lastName = response.data.last_name;
        store.profile.fullName = `${store.profile.firstName} ${store.profile.lastName}`;
        store.profile.reportDate = response.data.report_date;
        store.profile.reportRefreshDate = response.data.report_refresh_date;
        store.profile.unreadAlertCount = response.data.unread_alert_count;
        store.profile.currentReportID = response.data.current_report_id;
      });
    },
  },
  mounted() {
    window.addEventListener("hashchange", (e) => {
      const url = new URL(e.newURL);
      e.preventDefault();

      this.navigateToPath(url.hash.slice(1));
    });

    window.addEventListener("ss-global-alert", (e) => {
      this.alertText = e.detail;
      window.setTimeout(() => {
        this.alertText = "";
      }, 5000);
    });

    window.addEventListener("ss-global-notice", (e) => {
      this.noticeText = e.detail;
      window.setTimeout(() => {
        this.noticeText = "";
      }, 5000);
    });

    window.addEventListener("ss-alerts-read", () => {
      store.profile.unreadAlertCount = 0;
    });

    this.navigateToPath(window.location.hash.slice(1));

    this.fetchProfile();
    this.mountScriptTags();
  },
};
</script>

<template>
  <div class="portal">
    <TopNav />
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-500 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0">
      <div class="container mx-auto mt-3" v-if="alertText.length > 0">
        <div class="alert alert-danger">{{ alertText }}</div>
      </div>
    </Transition>
    <Transition
      enter-active-class="duration-300 ease-out"
      enter-from-class="transform opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-500 ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transform opacity-0">
      <div class="container mx-auto mt-3" v-if="noticeText.length > 0">
        <div class="alert alert-info">{{ noticeText }}</div>
      </div>
    </Transition>
    <component :is="currentView" />

    <FooterNav />
  </div>
</template>
