<template>
  <div>
    <ul class="relative flex flex-row gap-x-2">
      <li class="shrink basis-0 flex-1 group">
        <div class="min-w-7 min-h-7 w-full inline-flex items-center text-[20px] align-middle">
          <span v-bind:class="stepCss(1)">
            1
          </span>
          <div class="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden"></div>
        </div>
      </li>

      <li class="shrink basis-0 flex-1 group">
        <div class="min-w-7 min-h-7 w-full inline-flex items-center text-[20px] align-middle">
          <span v-bind:class="stepCss(2)">
            2
          </span>
          <div class="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden"></div>
        </div>
      </li>

      <li class="shrink basis-0 flex-1 group">
        <div class="min-w-7 min-h-7 w-full inline-flex items-center text-[20px] align-middle">
          <span v-bind:class="stepCss(3)">
            3
          </span>
          <div class="ms-2 w-full h-px flex-1 bg-gray-200 group-last:hidden"></div>
        </div>
      </li>

      <li class="shrink-0 group">
        <div class="min-w-7 min-h-7 inline-flex items-center text-[20px] align-middle">
          <span v-bind:class="stepCss(4)">
            4
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    currentStep: {
      type: Number,
      required: true
    }
  },
  methods: {
    stepCss(step) {
      if (this.currentStep === step) {
        return "size-10 flex justify-center items-center shrink-0 rounded-sm font-bold bg-gray-100 border text-black font-bold"
      } else if (this.currentStep > step) {
        return "size-10 flex justify-center items-center shrink-0 rounded-sm font-bold bg-green-light border border-green-medium text-green-medium"
      } else {
        return "size-10 flex justify-center items-center shrink-0 rounded-sm font-bold bg-gray-50 font-medium text-gray-300"
      }
    }
  }
}
</script>
