<template>
  <div>
    <input
      type="text"
      :value="formattedPhoneNumber"
      @input="onInput"
      placeholder="(XXX) XXX-XXXX"
    />
  </div>
</template>

<script>
export default {
  name: 'PhoneNumberInput',
  props: {
    phoneNumber: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
    };
  },
  computed: {
    formattedPhoneNumber() {
      return this.formatPhoneNumber(this.phoneNumber);
    }
  },
  methods: {
    sanitizeInput(input) {
      return input.replace(/\D/g, '').slice(0, 10);
    },
    formatPhoneNumber(phoneNumber) {
      const cleaned = phoneNumber.replace(/\D/g, '');
      const match = cleaned.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      if (!match) return '';

      let formatted = '';
      if (match[1]) formatted = `(${match[1]}`;
      if (match[2]) formatted += `) ${match[2]}`;
      if (match[3]) formatted += `-${match[3]}`;

      return formatted;
    },
    onInput(event) {
      const input = event.target.value;
      this.$emit('update:phoneNumber', this.sanitizeInput(input));
    }
  }
};
</script>
