<template>
  <div>
    <div class="border p-4 mt-5">
      <div class="text-lg font-medium mb-1">Question</div>

      <div class="mb-3 text-base-50 ccs-auth-question-text">
        {{ question.text }}
      </div>

      <div v-if="isMultipleChoice">
        <div class="text-lg font-medium mb-1">Select one</div>
        <div class="grid grid-cols-3 gap-2">
          <div v-for="(answer, answer_index) in question.answers" :key="answer.id" :id="`ccs-auth-question-answer-input:${index}:${answer_index}`" @click="selectAnswer(answer.id)" class="ccs-auth-answer-choice border rounded-md px-3 py-4 cursor-pointer text-[17px] leading-[24px]" v-bind:class="{ 'bg-accent-10 border-accent-20 text-base-60': selectedAnswers[question.id] === answer.id, 'text-base-50': selectedAnswers[question.id] !== answer.id }" >
            {{ answer.text }}
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(answer, answer_index) in question.answers" :key="answer.id">
          <input :id="`ccs-auth-question-answer-input:${index}:${answer_index}`" type="hidden"
          :name="`questions[${question.id}]`" :value="answer.id" />
          <input :id="`ccs-auth-question-answer-text:${index}:${answer_index}`" type="text"
            class="ccs-auth-question-answer-input" :name="`user_inputs[${question.id}]`"
            @input="(ev) => $emit('user-input', { answer_id: answer.id, user_input: ev.target.value })" required
            autofocus />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthQuestion",
  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      selectedAnswers: {}
    }
  },
  methods: {
    selectAnswer(answerId) {
      this.selectedAnswers[this.question.id] = answerId
      this.$emit('select-answer', answerId)
    }
  },
  computed: {
    isMultipleChoice() {
      return this.question.answers.some(answer => answer.type === 'radio')
    }
  }
}
</script>
