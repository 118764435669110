<template>
  <div id="step-report-preview">
    <div v-if="reportLoaded">
      <h2 class="text-[24px] leading-[32px] lg:text-[32px] lg:leading-[40px] text-base-60 font-medium my-8">Your Credit Scores</h2>
      <CreditScores :tucScore="store.creditReport.creditScore.tuc" :expScore="store.creditReport.creditScore.exp" :eqfScore="store.creditReport.creditScore.eqf" />

      <h3 class="text-[24px] leading-[32px] lg:text-[32px] lg:leading-[40px] text-base-60 font-medium my-8">These factors equal 100% of Your Score</h3>
      <ScoreFactors />

      <button onclick="window.location.href='/portal'" class="w-full bg-gray-200 text-base-60 py-3 rounded-lg shadow-md hover:bg-gray-300">
        Go To Dashboard →
      </button>
    </div>
    <div class="my-10 text-center" v-else>
      <div class="text-base-50">
        Please wait while we load your credit report...
      </div>
      <Spinner class="my-3" />
    </div>
  </div>
</template>

<script>
import { store } from "../portal_v2/store.ts";
import Spinner from "../shared/spinner_svg.vue";
import CreditScores from "./components/credit_scores.vue"
import ScoreFactors from "./components/score_factors.vue"
import CreditReportParser from "../../lib/credit_report_parser.ts";

export default {
  name: 'StepReportPreview',
  components: {
    CreditScores,
    ScoreFactors,
    Spinner
  },
  data: () => ({
    reportLoaded: false,
    store
  }),
  methods: {
    loadCreditReportScript(retry = false) {
      const script = document.createElement("script");
      script.async = true;
      script.type = "application/javascript";
      script.src = `/portal/credit-report.js${retry ? "?force=true" : ""}`;
      script.addEventListener("load", () => {
        this.loadCreditReportData(window.data);
      });
      script.addEventListener("error", () => {
        console.error("Failed to load the credit report script.");
      });

      document.body.appendChild(script);
    },
    loadCreditReportData(data) {
      try {
        if (Object.keys(data).length == 0 || data.ErrorCode == "8") {
          if (store.reportLoadAttempts == 0) {
            store.reportLoadAttempts = 1;
            this.loadCreditReportScript(true);
          } else {
            store.creditReportError = true;
          }
        }else{
          store.creditReport = CreditReportParser.parse(data?.BundleComponents?.BundleComponent);
          this.reportLoaded = true;
        }
      } catch (e) {
        window.Sentry.captureException(e);
      }
    },
  },
  mounted() {
    this.loadCreditReportScript();
  }
};
</script>
