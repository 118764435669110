import { createApp } from "vue"

import RegistrationForm from "../components/registration/registration_form.vue"
import RegistrationFormV2 from "../components/registration_v2/main.vue"
import ChoosePlan from "../components/registration/choose_plan.vue"
import UpdateInfo from "../components/registration/update_personal_info.vue"
import ProveAuth from "../components/registration/prove_auth.vue"

import CollectSSN from "../components/ccs_auth/collect_ssn.vue"
import CCSAuth from "../components/ccs_auth/question_container.vue"

import PortalRoot from "../components/portal/portal_root.vue"
import CreditReportDisplay from "../components/portal/credit_report/display.vue"

import moment from "moment"
import usdFormatter from "../lib/usd_formatter"
import * as Sentry from "@sentry/browser";

const components = {
  "dashboard": PortalRoot,
  "credit_report": CreditReportDisplay,
  "registration_form": RegistrationForm,
  "registration_form_v2": RegistrationFormV2,
  "choose_plan": ChoosePlan,
  "update_info": UpdateInfo,
  "collect_ssn": CollectSSN,
  "ccs_auth": CCSAuth,
  "prove_auth": ProveAuth
}

export default function (containerSelector, componentName) {
  const spreedlyEnvironmentId = window['spreedlyEnvironmentID'];

  if (!spreedlyEnvironmentId) {
    Sentry.captureMessage("Error, no spreedly environment ID specified");
  }

  const app = createApp(components[componentName], {});

  app.config.errorHandler = (err, instance, info) => {
    console.log(err)
    Sentry.captureException(err);
  };
  app.config.warnHandler = (msg, instance, trace) => {
    console.log(msg)
    Sentry.captureMessage(msg);
  };
  app.config.performance = true;
  app.config.globalProperties.window = window;
  app.config.globalProperties.usd = usdFormatter;
  app.config.globalProperties.formatDate = function (isoDateString, formatString) {
    if (!isoDateString || !isoDateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
      return null
    }

    try {
      return moment(isoDateString).format(formatString)
    } catch (e) {
      Sentry.captureException(e)
      return null
    }
  }
  app.provide("spreedly.environmentId", spreedlyEnvironmentId);
  app.mount(containerSelector)
}
