<template>
  <div>
    <h3 class="mt-5 text-[28px] tracking-tight text-base-60 font-medium">
      Customer Feedback
    </h3>
    <div class="feedback-carousel my-3 min-h-[420px] text-center border border-gray-300 bg-base-20 rounded-sm relative">
      <transition name="slide-up">
        <div class="feedback-card absolute p-5 mb-5" :key="currentIndex">
          <h3 class="my-4 font-medium text-base-60 text-[16px]">{{ feedbacks[currentIndex].name }}</h3>
          <p class="font-light text-[16px]">{{ feedbacks[currentIndex].text }}</p>
          <div class="stars text-base-50 text-[24px]">★★★★★</div>
        </div>
      </transition>
    </div>
    <div class="navigation flex justify-center gap-3 my-3">
      <span
        v-for="(feedback, index) in feedbacks"
        :key="index"
        :class="{ 'bg-gray-800': index === currentIndex, 'bg-gray-300': index !== currentIndex }"
        class="w-2.5 h-2.5 rounded-full mx-1.25 cursor-pointer"
        @click="currentIndex = index"></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      feedbacks: [
        {
          name: "Michael R.",
          text: "I was able to see all my scores from Equifax, TransUnion and Experian. This enabled me to purchase a car prior to walking into the dealership because I was armed with information.",
        },
        {
          name: "Sarah T.",
          text: "So easy to use! With a clear breakdown of my credit history, I was able to secure a lower interest rate on my mortgage, saving me thousands in the long run!",
        },
        {
          name: "James L.",
          text: "I had some errors on my credit report that I wasn't even aware of. Thanks to Standard Scores, I caught and disputed them quickly. Within a few weeks, my score improved, and I qualified for a much better credit card offer!",
        },
        {
          name: "Emily K.",
          text: "Super user-friendly and informative! Thanks to this service, I built my credit up and was able to lease my first apartment with no issues!",
        },
      ],
    };
  },
};
</script>
