<template>
  <div id="step-ccs-questions">
    <PrivacySvg class="my-8" />
    <form action="/" v-on:submit.prevent="submit()" v-on:keyup.enter="submit()">
      <div class="w-full mx-auto">
        <h2 class="text-3xl text-base-60 font-medium">Verification Information</h2>

        <div v-if="showSpinner == false && questions.length == 0" class="my-5 text-base-50">
          No identity verification questions available - please contact support for further assistance.
        </div>

        <div v-for="(question, index) in questions" :key="question.id">
          <AuthQuestion
            :question="question"
            :index="index"
            @select-answer="
              (answerID) => {
                question.answer_id = answerID;
              }
            "
            @user-input="
              (answerData) => {
                question.answer_id = answerData.answer_id;
                question.user_input = answerData.user_input;
              }
            " />
        </div>

        <div v-if="errors.length > 0" class="validation-error my-3">
          <div v-for="error in errors" v-bind:key="error">
            {{ error }}
          </div>
        </div>

        <div class="my-5">
          <button id="btn-submit" type="button" class="w-full px-3 py-4 text-[34px] leading-[46px] text-base-60 font-medium bg-accent-30 hover:bg-amber-500" v-on:click="submit()" :disabled="showSpinner">
            <span v-if="!showSpinner">Submit & Continue</span>
            <Spinner v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
// This component will either display the OTP verification or multiple choice questions
import axios from "axios";
import Spinner from "../shared/spinner_svg.vue"
import AuthQuestion from "./components/auth_question.vue"
import PrivacySvg from "./components/privacy_svg.vue"

export default {
  name: "RegistrationFormV2StepCcsQuestions",
  props: ["userProfile"],
  components: {
    Spinner,
    AuthQuestion,
    PrivacySvg
  },
  data() {
    return {
      questions: [],
      ssn: "",
      showSpinner: true,
      errors: []
    }
  },
  methods: {
    allQuestionsAnswered() {
      return this.questions.every(question => question.answer_id || (question.user_input && question.user_input.length > 0))
    },
    submit() {
      if (!this.allQuestionsAnswered()) {
        this.errors = ["Please answer all questions"]
        return
      }

      this.showSpinner = true

      axios
        .post("/sign-up/submit-ccs-answers", {
          questions: this.questions,
        })
        .then((response) => {
          if (response.data.result == "success") {
            if (response.data.questions && response.data.questions.length > 0) {
              this.questions = response.data.questions;
              this.ssn = response.data.ssn
              this.answerProvided = false;
            } else {
              this.$emit("next-step");
            }
          } else {
            this.errors = response.data.errors;

            if (response.data.auth_failed) {
              window.location = "/sign-up/auth-failed"
            }
          }
        }).finally(() => {
          this.showSpinner = false
        });
    }
  },
  mounted() {
    axios.get("/sign-up/ccs-questions").then((response) => {
      this.ssn = response.data.ssn
      this.questions = response.data.questions;
    }).finally(() => {
      this.showSpinner = false
    });
  }
}
</script>
