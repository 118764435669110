<template>
  <div>
    <div class="grid grid-cols-2 gap-4 mb-6">
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">40% Payment History</div>
      </div>
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">21% Account mix & credit age</div>
      </div>
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">20% Credit Utilization</div>
      </div>
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">11% Balances & Debt</div>
      </div>
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">5% New Activity</div>
      </div>
      <div class="flex items-center gap-3">
        <div class="bg-green-light p-1">
          <CheckIcon class="w-6 h-5 text-green-medium" />
        </div>
        <div class="text-[16px] leading-[32px] text-base-50 py-2">3% Available Credit</div>
      </div>
    </div>
  </div>
</template>

<script>
import { CheckIcon } from "@heroicons/vue/24/solid"

export default {
  components: {
    CheckIcon
  }
}
</script>
