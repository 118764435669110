import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "text",
      value: $options.formattedPhoneNumber,
      onInput: _cache[0] || (_cache[0] = (...args) => ($options.onInput && $options.onInput(...args))),
      placeholder: "(XXX) XXX-XXXX"
    }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1)
  ]))
}