<template>
  <div id="step-payments">
    <div class="border bg-gray-50 rounded-sm p-8 my-8">
      <h3 class="font-semibold text-[20px] mb-2">
        {{ userProfile.firstName }}, your identity has been verified!
      </h3>

      <div class="grid grid-cols-4 gap-4 text-base-60 text-center">
        <div class="bg-white p-2">
          <div class="text-[14px]">Collections</div>
          <h2 class="font-medium text-[20px]">{{ accountSummary.totalOpenCollectionAccounts }}</h2>
        </div>
        <div class="bg-white p-2">
          <div class="text-[14px]">Loans</div>
          <h2 class="font-medium text-[20px]">{{ accountSummary.totalOpenInstallmentAccounts }}</h2>
        </div>
        <div class="bg-white p-2">
          <div class="text-[14px]">Real Estate</div>
          <h2 class="font-medium text-[20px]">{{ accountSummary.totalOpenMortgageAccounts }}</h2>
        </div>
        <div class="bg-white p-2">
          <div class="text-[14px]">Revolving Credit</div>
          <h2 class="font-medium text-[20px]">{{ accountSummary.totalOpenRevolvingAccounts }}</h2>
        </div>
      </div>
    </div>
    <PrivacySvg class="my-8" />
    <form action="/checkout" v-on:submit.prevent="submit()" v-on:keyup.enter="submit()">
      <div class="w-full mx-auto">
        <h2 class="text-3xl text-base-60 font-medium">Payment Information</h2>
        <p class="text-base-50 font-light text-[18px] my-2">Tell us which card you would like to use for the $1.00 refundable processing fee and membership.</p>

        <div class="border p-4 mt-5">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
            <div>
              <label for="name_on_card">Name on Card</label>
              <input type="text" id="name_on_card" v-model="nameOnCard" placeholder="Enter name on card" />
            </div>
            <div>
              <label for="card_number">Card Number</label>
              <div id="card_number" style="height: 38px" class="w-full px-2 py-1.5 border border-slate-200 bg-white"></div>
            </div>
            <div>
              <label for="expiry">Expiry</label>
              <input
                id="expiry"
                type="text"
                inputmode="numeric"
                :value="expiry"
                @input="maskCCExpiry($event.target.value)"
                class="w-full border-slate-200"
                style="font-family: Arial, sans-serif"
                placeholder="MM/YY" />
            </div>
            <div>
              <label for="cvc">CVC</label>
              <div id="cvc" style="height: 38px" class="w-full px-2 py-1.5 border border-slate-200 bg-white"></div>
            </div>
          </div>
        </div>

        <!-- Offer details section -->
        <div class="bg-gradient-to-b from-[#13143A] to-[#06071A] text-white p-8 mt-5">
          <h3 class="font-medium text-[24px] mb-2">Offer details</h3>
          <ul class="list-none">
            <li class="px-4 py-2 my-4">
              <div class="flex gap-3">
                <div><CheckIcon class="w-6 h-6" /></div>
                <div>Free trial ends on {{ trialEndDate }}</div>
              </div>
            </li>
            <li class="px-4 py-2 my-4">
              <div class="flex gap-3">
                <div><CheckIcon class="w-6 h-6" /></div>
                <div>Monthly Membership of ${{ planAmount }} is automatically charged after the free trial period, unless cancelled</div>
              </div>
            </li>
            <li class="px-4 py-2 my-4">
              <div class="flex gap-3">
                <div><CheckIcon class="w-6 h-6" /></div>
                <div>Call us on {{ customerSupportPhoneNumber }}</div>
              </div>
            </li>
          </ul>
        </div>

        <div v-if="errors.length > 0" class="validation-error my-3">
          <div v-for="error in errors" v-bind:key="error">
            {{ error }}
          </div>
        </div>

        <div class="my-5">
          <button id="btn-submit" type="button" class="w-full px-3 py-4 text-[34px] leading-[46px] text-base-60 font-medium bg-accent-30 hover:bg-amber-500" v-on:click="submit()" :disabled="showSpinner">
            <span v-if="!showSpinner">Submit & Continue</span>
            <Spinner v-else />
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
/* global Spreedly */
import axios from "axios";
import Spinner from "../shared/spinner_svg.vue";
import PrivacySvg from "./components/privacy_svg.vue";
import { CheckIcon } from "@heroicons/vue/24/solid"

export default {
  name: "StepPayments",
  components: {
    Spinner,
    PrivacySvg,
    CheckIcon
  },
  props: [
    "userProfile",
    "customerSupportPhoneNumber",
    "trialEndDate",
    "selectedPlanSlug",
    "planAmount"
  ],
  data() {
    return {
      errors: [],
      nameOnCard: "",
      cardNumber: "",
      expiry: "",
      cvc: "",
      paymentFieldsReady: false,
      accountSummary: {},
      showSpinner: false,
    };
  },
  computed: {
    canCompletePayment() {
      return this.paymentFieldsReady && this.selectedPlanSlug;
    },
    expiryMonth() {
      if (!this.expiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      return this.expiry.substring(0, 2);
    },
    expiryYear() {
      if (!this.expiry.match(/\d\d\/\d\d/)) {
        return "";
      }

      // This code will need to be adjusted in another 1000 years
      return `20${this.expiry.substring(3)}`;
    },
  },
  methods: {
    maskCCExpiry(string) {
      const upToFourDigits = string.replace(/\D/g, "").substring(0, 4);

      if (upToFourDigits.length < 2) {
        this.expiry = upToFourDigits;
      } else {
        // Add a slash after the 2nd character
        this.expiry = upToFourDigits.substring(0, 2) + "/" + upToFourDigits.substring(2);
      }
    },
    validateCCExpiry() {
      if (!this.expiry.match(/\d\d\/\d\d/) || parseInt(this.expiryYear) < new Date().getFullYear()) {
        this.errors = ["Please enter a valid expiry date"];
        return false;
      }
      return true;
    },
    submit() {
      if (!this.validateCCExpiry()) {
        return;
      }

      this.showSpinner = true;
      let data = {
        full_name: this.nameOnCard,
        month: this.expiryMonth,
        year: this.expiryYear,
      };

      Spreedly.tokenizeCreditCard(data);
    },
    submitPaymentToken(token) {
      this.showSpinner = true;

      axios
        .post("/sign-up/checkout", {
          payment_method_token: token,
          plan: this.selectedPlanSlug,
        })
        .then((response) => {
          if (response.data.result == "success") {
            this.paymentCompleted = true;
            this.$emit("next-step");
          } else {
            this.showSpinner = false;
            this.errors = response.data.errors;
          }
        })
        .catch((e) => {
          window.Sentry.captureException(e);
          this.showSpinner = false;
          this.errors = ["An error occurred while attempting to process your request"];
        });
    },
    initializeSpreedly() {
      if (this.paymentFieldsReady) {
        return;
      }
      if (this.spreedlyInitialized) {
        return;
      }

      this.spreedlyInitialized = true;
      this.showSpinner = true;

      Spreedly.init(window.spreedlyEnvironmentID, {
        numberEl: "card_number",
        cvvEl: "cvc",
      });

      Spreedly.on("ready", () => {
        this.paymentFieldsReady = true;
        this.showSpinner = false;

        Spreedly.setFieldType("number", "text");
        Spreedly.setNumberFormat("prettyFormat");
        Spreedly.setStyle("number", "color: #979CBA; font-size: 16px; line-height: 24px; width: 100%");
        Spreedly.setStyle("cvv", "color: #979CBA; font-size: 16px; line-height: 24px;");
      });

      Spreedly.on("errors", (errors) => {
        this.errors = errors.map((e) => e.message);
        this.showSpinner = false;
      });

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Spreedly.on("paymentMethod", (token, pmData) => {
        this.submitPaymentToken(token);
      });
    },
    fetchAccountSummary() {
      axios.get("/sign-up/account-summary").then((response) => {
        this.accountSummary = {
          totalOpenRevolvingAccounts: response.data.total_open_revolving_accounts || "-",
          totalOpenInstallmentAccounts: response.data.total_open_installment_accounts || "-",
          totalOpenMortgageAccounts: response.data.total_open_mortgage_accounts || "-",
          totalOpenCollectionAccounts: response.data.total_open_collection_accounts || "-",
        }
      });
    },
  },
  mounted() {
    this.fetchAccountSummary();
    this.initializeSpreedly();
  },
};
</script>
