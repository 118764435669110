<template>
  <div class="w-full h-full p-2">
    <div class="container-registration mx-auto">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-10 text-base-50 text-[14px] md:text-center">
        <div>
          7 day free trial ends on<br />{{ trialEndDate }}
        </div>
        <div>Monthly membership fee of ${{ planAmount }}<br />automatically charged after free trial</div>
        <div>For questions or help,<br />call {{ customerSupportPhoneNumber }}</div>
      </div>
    </div>
    <div id="registration-v2" class="flex flex-col lg:flex-row gap-5 container-registration mx-auto">
      <div class="flex-grow">
        <ProgressIndicator :currentStep="stepNumber" />
        <Spinner v-if="showSpinner" />
        <RegistrationFormV2StepRegistration :userProfile="userProfile" v-if="currentStep === 'registration'" @next-step="goToIdentityVerification" />
        <RegistrationFormV2StepIdentityVerification :userProfile="userProfile" v-if="currentStep === 'identity-verification'" @next-step="goToAuthQuestions" />
        <RegistrationFormV2StepCcsQuestions :userProfile="userProfile" v-if="currentStep === 'auth-questions'" @next-step="goToPayments" />
        <RegistrationFormV2StepPayments
          v-if="currentStep === 'payments'"
          @next-step="goToReportPreview"
          :userProfile="userProfile"
          :customerSupportPhoneNumber="customerSupportPhoneNumber"
          :trialEndDate="trialEndDate"
          :selectedPlanSlug="selectedPlanSlug"
          :planAmount="planAmount" />
        <RegistrationFormV2StepReportPreview v-if="currentStep === 'report-preview'" />
      </div>
      <div style="width: 300px;" class="flex-none">
        <h3 style="font-size: 20px;" class="text-base-60 bg-gray-50 py-1 px-2 font-semibold">Standard Scores Features</h3>

        <div class="p-2 text-sm text-base-50">
          <div class="flex gap-3 my-2">
            <div class="bg-green-light p-1">
              <CheckIcon class="w-6 h-5 text-green-medium" />
            </div>
            <div class="mt-1 font-medium">Daily Monitoring & Alerts</div>
          </div>
          <div class="flex gap-3 my-2">
            <div class="bg-green-light p-1">
              <CheckIcon class="w-6 h-5 text-green-medium" />
            </div>
            <div class="mt-1 font-medium">All 3 Credit Scores</div>
          </div>
          <div class="flex gap-3 my-2">
            <div class="bg-green-light p-1">
              <CheckIcon class="w-6 h-5 text-green-medium" />
            </div>
            <div class="mt-1 font-medium">$1 Million Identity Theft Insurance*</div>
          </div>
          <div class="flex gap-3 my-2">
            <div class="bg-green-light p-1">
              <CheckIcon class="w-6 h-5 text-green-medium" />
            </div>
            <div class="mt-1 font-medium">Credit Insights & Discrepancies</div>
          </div>
        </div>

        <div class="border p-5 my-4 bg-base-20">
          <h3 style="font-size: 24px;" class="text-base-60 font-medium">Get free live help</h3>

          <p class="text-base-60 font-light my-3">Personal Credit Specialists will help you make sense of your credit, every time you call or online chat.</p>

          <img src="/images/registration_v2/livechat.jpeg" class="w-full rounded-sm" />
        </div>

        <CustomerFeedback />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import CustomerFeedback from "./components/customer_feedback.vue"
import ProgressIndicator from "./components/progress_indicator.vue"
import Spinner from "../shared/spinner_svg.vue"
import RegistrationFormV2StepRegistration from "./step_registration.vue"
import RegistrationFormV2StepIdentityVerification from "./step_identity_verification.vue"
import RegistrationFormV2StepCcsQuestions from "./step_ccs_questions.vue"
import RegistrationFormV2StepPayments from "./step_payments.vue"
import RegistrationFormV2StepReportPreview from "./step_report_preview.vue"
import { CheckIcon } from "@heroicons/vue/24/solid"

export default {
  name: "RegistrationFormV2",
  components: {
    RegistrationFormV2StepRegistration,
    RegistrationFormV2StepIdentityVerification,
    RegistrationFormV2StepCcsQuestions,
    RegistrationFormV2StepPayments,
    RegistrationFormV2StepReportPreview,
    CheckIcon,
    ProgressIndicator,
    Spinner,
    CustomerFeedback
  },
  data: () => ({
    currentStep: '',
    showSpinner: true,
    plans: [],
    selectedPlanSlug: "premium-plan",
    userProfile: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      ssn: "",
      dateOfBirth: "",
      confirmPassword: "",
      marketingConsent: true,
      useCurrentAddress: true,
      creditCheckReason: "recently_denied_credit",
      address: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      },
      previousAddress: {
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        zip: ""
      }
    }
  }),
  methods: {
    goToIdentityVerification(user) {
      this.userProfile = user;
      this.currentStep = 'identity-verification';
      window.history.pushState({}, '', '/sign-up/identity-verification');
    },
    goToAuthQuestions() {
      this.currentStep = 'auth-questions';
      window.history.pushState({}, '', '/sign-up/auth-questions');
    },
    goToPayments() {
      this.currentStep = 'payments';
      window.history.pushState({}, '', '/sign-up/payments');
    },
    goToReportPreview() {
      this.currentStep = 'report-preview';
      window.history.pushState({}, '', '/sign-up/report-preview');
    },
    checkAuthStatus() {
      axios.get("/sign-up/registration-status")
        .then(response => {
          if (response.data.status === "registered") {
            this.userProfile.firstName = response.data.first_name
            this.userProfile.lastName = response.data.last_name
            if (response.data.ccs_status == "none") {
              this.currentStep = 'identity-verification'
              this.setStepUrl("/sign-up/identity-verification")
            } else if (response.data.ccs_status == "questions") {
              this.currentStep = 'auth-questions'
              this.setStepUrl("/sign-up/auth-questions")
            } else if (response.data.ccs_status == "authenticated") {
              if (response.data.active_subscription) {
                this.currentStep = 'report-preview'
                this.setStepUrl("/sign-up/report-preview")
              } else {
                this.currentStep = 'payments'
                this.setStepUrl("/sign-up/payments")
              }
            }
          } else {
            // TODO: If the current step is not registration, flash a message to
            // the user saying that maybe they need to log back in to resume
            // their registration flow
            this.currentStep = 'registration'
            this.setStepUrl("/sign-up/registration")
          }
        }).catch(error => {
          console.error(error)
          this.currentStep = 'registration'
          this.setStepUrl("/sign-up/registration")
        }).finally(() => {
          this.showSpinner = false
        })
    },
    setStepUrl(stepUrl) {
      if (!window.location.href.includes(stepUrl)) {
        window.history.pushState({}, '', stepUrl);
      }
    },
    fetchAvailablePlans() {
      axios
        .get("/registration/available-plans")
        .then((response) => {
          this.plans = response.data.plans;
        })
        .catch((e) => {
          window.Sentry.captureException(e);
        });
    },
  },
  computed: {
    stepNumber() {
      if (this.currentStep === 'payments' || this.currentStep == 'report-preview') {
        return 4
      } else if (this.currentStep === 'auth-questions') {
        return 3
      } else if (this.currentStep === 'identity-verification') {
        return 2
      }

      return 1
    },
    trialEndDate() {
      return new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })
    },
    customerSupportPhoneNumber() {
      return window.customerSupportPhoneNumber
    },
    selectedPlan() {
      if (this.selectedPlanSlug) {
        return this.plans.find(plan => plan.slug === this.selectedPlanSlug);
      } else {
        return null;
      }
    },
    planAmount() {
      if (this.selectedPlan) {
        // Return the dollar amount formatted as XX.XX
        return parseFloat(this.selectedPlan.recurring_fee).toFixed(2);
      } else {
        return null;
      }
    },
  },
  mounted() {
    this.checkAuthStatus()
    this.fetchAvailablePlans();
  }
}
</script>
